import React from "react";

const PdfItem = ({ pdf, openModal, cover }) => {
  return (

    <div className="catalogs-item">
      <img src={cover} alt="catalog" />
      <div className="see-items">{pdf.title}</div>
      <div className="open-item">
        <p
          className="open-item-text"
          onClick={(e) => {
            e.preventDefault();
            openModal(pdf);
          }}
        >{`${
          pdf.passwordProtected === 'yes'
            ? "CONFIDENTIAL FOR AUTHORIZED WIP DISTRIBUTORS ONLY"
            : "OPEN TO THE PUBLIC"
        }`}</p>
      </div>
    </div>
  );
};

export default PdfItem;
