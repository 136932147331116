import React, { useState } from 'react';

const PasswordModal = ({ modal, setModal, lockedPdf, checkPassword }) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState(null);

    const onChange = (e) => setValue(e.target.value);

    // Close Modal
    const closeModal = (e) => {
        const modalContainer = e.target.classList.contains('pw-modal')
        const closeModal = e.target.classList.contains('pw-modal-close');

        if (modalContainer || closeModal) {
            setModal(false);
            setValue('');
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const verified = await checkPassword(value);

        if(verified) {
            window.location.href = lockedPdf;

            setModal(false);
            setValue('');
        } else {
            setError('Incorrect password');

            setTimeout(() => {
                setError(null);
            }, 3000)
        }
    }

    return (
        <div className={`pw-modal ${modal ? 'show' : ''}`} onClick={closeModal}>
            <div className="pw-modal-content">

                {/* Header */}
                <div className="pw-modal-header">
                    <span className="pw-modal-close">&times;</span>
                </div>

                {/* Body */}
                <div className="pw-modal-body">
                    <p>This file is confidential for Westgate WIP customers. To obtain the password please contact your Westgate sales representative.</p>
                    <form>
                        <input type="password" placeholder="Enter password" value={value} onChange={onChange} />
                        { error && <p className="form-error"> { error }</p>}
                        <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PasswordModal;
