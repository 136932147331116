export default function Intro() {
  return (
    <div className="intro">
      <div className="header">
        <div className="header-text">WESTGATE HOTSHEET AND PRODUCTSHEET</div>
      </div>
      <img className="intro-img" src="logoWest.png" alt="WestGate Logo" />
      <a href="https://westgatemfg.com/">
        {/* <div className="intro-main"> */}
        <p className="intro-main-p">VISIT WESTGATEMFG.COM</p>
        {/* </div> */}
      </a>
    </div>
  );
}
