import React, { useState, useEffect } from "react";
import PasswordModal from "./components/PasswordModal";
import PdfItem from "./components/PdfItem";
import axios from "axios";
import Intro from "./components/Intro";

const URL = "https://westgate-1504085677899.appspot.com";

function App() {
  const [pdfs, setPdfs] = useState([]);

  const [modal, setModal] = useState(false);
  const [lockedPdf, setLockedPdf] = useState(null);

  // Generate links
  const getPdfLink = (name) => {
    const baseURL =
      "https://firebasestorage.googleapis.com/v0/b/westgatedash-d1341.appspot.com/o/pdfs%2F";
    return `${baseURL}${name}?alt=media`;
  };

  const getImageLink = (img) => {
    const baseURL =
      "https://firebasestorage.googleapis.com/v0/b/westgatedash-d1341.appspot.com/o/pdfImages%2F";
    return `${baseURL}${img}?alt=media`;
  };

  useEffect(() => {
    getPdfs();
  }, []);

  // Fetch pdfs
  const getPdfs = async () => {
    const res = await axios.get(`${URL}/api/pdfs`);
    setPdfs(res.data);
  };

  // Check password
  const checkPassword = async (pw) => {
    const res = await axios.get(`${URL}/api/pdfs/verifyPdf/${pw}`);
    return res.data.data;
  };

  // Open Modal (pdfs from dashboard)
  const openModal = (pdf) => {
    if (pdf.passwordProtected === "yes") {
      setLockedPdf(getPdfLink(pdf.pdf));
      setModal(true);
    } else {
      window.location.href = getPdfLink(pdf.pdf);
    }
  };

  return (
    <div className="App">
      <Intro />
      <div className="container">
        <div className="mt-4 catalog-container">
          {pdfs &&
            pdfs.map((pdf) => (
              <PdfItem
                cover={getImageLink(pdf.image)}
                key={pdf.pdfId}
                pdf={pdf}
                openModal={openModal}
              />
              // <PdfItem cover={pdf.image} key={pdf.pdfId} pdf={pdf} openModal={openModal} />
            ))}
        </div>

        <PasswordModal
          modal={modal}
          setModal={setModal}
          lockedPdf={lockedPdf}
          checkPassword={checkPassword}
        />
      </div>
      <div className="footer">
        <div className="westmfg">
          <a href="https://westgatemfg.com/">
            <h2 className="westmfg-text">VISIT WESTGATEMFG.COM</h2>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
